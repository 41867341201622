import { onAuthStateChanged } from "firebase/auth"
import { useEffect, useState } from "react"
import { useAppDispatch } from "../redux"
import { AppActions } from "../redux/appSlice"
import { createCreator, getCreator, isUserAdmin } from "../services"
import { getCreatorFromUser } from "../types"
import { firebaseAuth, resetMixpanel, setUserOnMixpanel } from "../utils"

export function useFirebaseAuth() {
  const [loading, setLoading] = useState<boolean>(true)

  const dispatch = useAppDispatch()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(firebaseAuth, async (user) => {
      if (user) {
        setUserOnMixpanel(user)

        try {
          const creator = await getCreator(user.uid)
          const userPayload = {
            uid: user.uid,
            name: user.displayName || "",
            email: user.email,
            subscriptionPlan: creator.subscriptionPlan,
            subscriptionState: creator.subscriptionState,
            subscriptionBillingCycleAnchor:
              creator.subscriptionBillingCycleAnchor,
            termsAccepted: creator.termsAccepted ? true : false,
          }
          dispatch(AppActions.updateUser(userPayload))
        } catch (error) {
          const creator = await createCreator(
            getCreatorFromUser({
              email: user.email,
              name: user.displayName || "",
              uid: user.uid,
              termsAccepted: false,
            }),
          )
          const userPayload = {
            uid: user.uid,
            name: user.displayName || "",
            email: user.email,
            termsAccepted: creator.termsAccepted ? true : false,
          }
          dispatch(AppActions.updateUser(userPayload))
          console.error("Error fetching creator:", error)
        }

        const isAdmin = await isUserAdmin()
        dispatch(AppActions.updateIsUserAdmin(isAdmin))
        setLoading(false)
      } else {
        dispatch(AppActions.resetState())
        resetMixpanel()
      }
      setLoading(false)
    })
    return () => {
      unsubscribe()
    }
  }, [firebaseAuth])

  return [loading]
}
