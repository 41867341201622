export const isSignedUrlStillValid = async (url: string): Promise<boolean> => {
  try {
    const resp = await fetch(url, {
      method: "HEAD",
    })
    return resp.ok
  } catch (e) {
    return false
  }
}
