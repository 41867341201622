import { ThemeProvider } from "@emotion/react"
import { createTheme, useMediaQuery } from "@mui/material"
import CssBaseline from "@mui/material/CssBaseline"
import React, { Fragment, useMemo } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { Bounce, ToastContainer } from "react-toastify"
import { StripePaymentModal } from "../components/modals"
import { FullScreenLoader } from "../components/ui/fullScreenLoader"
import { useFirebaseAuth, useGetSubscription } from "../hooks"
import { useAppDispatch, useAppSelector } from "../redux"
import { AppActions } from "../redux/appSlice"
import { initializeMixpanel, initializeSentry } from "../utils"
import { getRouterData } from "./routes/router"
import { getTheme } from "./theme"

initializeSentry()
initializeMixpanel()

function App() {
  useGetSubscription()
  const [loading] = useFirebaseAuth()

  const theme = createTheme(getTheme)
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const showStripeModal = useAppSelector((state) => state.app.showStripeModal)
  const user = useAppSelector((state) => state.app.user)
  const dispatch = useAppDispatch()

  const handleCloseStripeModal = () => {
    dispatch(AppActions.updateShowStripeModal(false))
  }

  const router = useMemo(
    () => createBrowserRouter(getRouterData(user, isMobile)),
    [user, isMobile],
  )

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <Fragment>
      <ThemeProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <RouterProvider router={router} />
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
          transition={Bounce}
        />
      </ThemeProvider>
      <StripePaymentModal
        visible={showStripeModal}
        onClose={handleCloseStripeModal}
      />
    </Fragment>
  )
}

export default App
