import { Box, Grid, Pagination, Skeleton, Typography } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { MainLayout, SnackbarWithText, Topbar } from "../../../components"
import { DeletePopup } from "../../../components/ui/deletePopup"
import { EmptyCard } from "../../../components/video/"
import ResponsiveVideoPlayerModal from "../../../components/video/ResponsiveVideoPlayerModal"
import { VideosList } from "../../../components/video/videosList"
import { useAppDispatch, useAppSelector } from "../../../redux"
import { AppActions } from "../../../redux/appSlice"
import {
  deleteVideo,
  getAnalyticsProperties,
  getLoadVideoUrl,
  listVideos,
  logAnalyticsEvent,
} from "../../../services"
import { listenForVideo } from "../../../services/db"
import {
  AnalyticsEvents,
  AnalyticsProducts,
  ApiState,
  Colors,
  initialSnackbarProps,
  isTypeofVideo,
  ModuleName,
  PageMode,
  Screen,
  Video,
  VideoScreen,
} from "../../../types"
import { isSignedUrlStillValid } from "../../../utils"

export function TextToVideoScreen() {
  const [videos, setVideos] = useState<Video[]>([])
  const [snackbarProps, setSnackbarProps] = useState(initialSnackbarProps)
  const [isLoading, setIsLoading] = useState(false)
  const [deletePopupOpen, setDeletePopupOpen] = useState(false)
  const [videoToDelete, setVideoToDelete] = useState<Video | null>(null)
  const [videoToPreview, setVideoToPreview] = useState<Video | null>(null)
  const [allVideos, setAllVideos] = useState<Video[] | null>(null)
  const [currentPageVideos, setCurrentPageVideos] = useState<Video[]>([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const videosPerPage = 6

  const navigate = useNavigate()

  const dispatch = useAppDispatch()
  const user = useAppSelector((state) => state.app.user)

  const [isInitialLoading, setIsInitialLoading] = useState(true)

  useEffect(() => {
    const fetchAllVideos = async () => {
      try {
        const fetchedVideos = (await listVideos()).filter(
          (e) => e.campaignId == null,
        )
        setAllVideos(fetchedVideos)
        setTotalPages(Math.ceil(fetchedVideos.length / videosPerPage))
        const processingVideos = fetchedVideos.filter(
          (e) => e.state === ApiState.processing,
        )
        processingVideos.forEach((video) => {
          console.log("adding listener for ", video.id)
          listenForVideo(video.id, user).then((updatedVideo) => {
            setAllVideos((prevVideos) => {
              return prevVideos.map((prevVideo) => {
                if (prevVideo.id === updatedVideo.id) {
                  return updatedVideo
                }
                return prevVideo
              })
            })
          })
        })
      } catch (error) {
        console.error("Error fetching videos:", error)
        setSnackbarProps({ open: true, text: "Error loading videos" })
      } finally {
      }
    }

    fetchAllVideos()
  }, [])

  useEffect(() => {
    if (allVideos == null) return
    setIsInitialLoading(false)
  }, [allVideos])

  useEffect(() => {
    const loadCurrentPageVideos = async () => {
      if (allVideos == null) return
      if (allVideos.length === 0) {
        setCurrentPageVideos([])
        setIsLoading(false)
        return
      }

      const startIndex = (page - 1) * videosPerPage
      const endIndex = startIndex + videosPerPage
      const pageVideos = allVideos.slice(startIndex, endIndex)

      const alreadyLoadedVideos = await Promise.all(
        pageVideos
          .filter((e) => e.state === ApiState.ready)
          .map(async (v) => {
            if (v.url && v.previewUrl) {
              const isUrlValid = await isSignedUrlStillValid(v.url)
              const isPreviewUrlValid = await isSignedUrlStillValid(
                v.previewUrl,
              )
              return isUrlValid && isPreviewUrlValid ? v : null
            }
            return null
          }),
      ).then((results) => results.filter((v) => v !== null))
      const loadedVideos = []

      if (alreadyLoadedVideos.length !== pageVideos.length) {
        setIsLoading(true)
        const notYetLoadedVideos = pageVideos.filter(
          (v) => !alreadyLoadedVideos.includes(v),
        )
        await Promise.all(
          notYetLoadedVideos.map(async (v) =>
            getLoadVideoUrl(v.id)
              .catch(() => "")
              .then((video) => loadedVideos.push(video)),
          ),
        )
      }
      setCurrentPageVideos(
        pageVideos.map(
          (video) =>
            alreadyLoadedVideos
              .concat(loadedVideos)
              .find((v) => v.id === video.id) || video,
        ),
      )
      setIsLoading(false)
    }

    loadCurrentPageVideos()
  }, [page, allVideos])

  useEffect(() => {
    if (videoToPreview && isTypeofVideo(videoToPreview)) {
      const req = async () => {
        if (!videoToPreview.url) {
          const video = await getLoadVideoUrl(videoToPreview.id).catch(
            (e) => "",
          )
          setVideoToPreview(video)
        }
      }
      req()
    }
  }, [videoToPreview])

  const handlePreviewVideo = (item: Video) => {
    setVideoToPreview(item)
  }

  const createNewVideo = async () => {
    logAnalyticsEvent(
      AnalyticsEvents.ttv_started,
      getAnalyticsProperties(AnalyticsProducts.text_to_video),
    )
    dispatch(AppActions.updatePageMode(PageMode.CREATE))
    dispatch(AppActions.updateOpenedModule(ModuleName.videos))
    navigate(`/${Screen.videos}/${VideoScreen.selectVideoAvatar}`)
  }

  const deleteSelectedVideo = async () => {
    if (!videoToDelete) return

    setIsLoading(true)
    try {
      await deleteVideo(videoToDelete)
      setAllVideos((prevVideos) =>
        prevVideos.filter((video) => video.id !== videoToDelete.id),
      )
      setCurrentPageVideos((prevVideos) =>
        prevVideos.filter((video) => video.id !== videoToDelete.id),
      )
      // setVideos((prevVideos) =>
      //   prevVideos.filter((video) => video.id !== videoToDelete.id),
      // )

      const newTotalPages = Math.ceil((allVideos.length - 1) / videosPerPage)
      setTotalPages(newTotalPages)
      if (currentPageVideos.length === 1 && page > 1) {
        setPage((prevPage) => Math.min(prevPage - 1, newTotalPages))
      }

      toast.success("Video deleted successfully")
    } catch (e) {
      console.error("Error deleting video:", e)
      setSnackbarProps({ open: true, text: "Error deleting video" })
    } finally {
      setIsLoading(false)
      setDeletePopupOpen(false)
      setVideoToDelete(null)
    }
  }

  const cancelDeletion = () => {
    setVideoToDelete(null)
    setDeletePopupOpen(false)
  }

  const SkeletonLoader = () => (
    <Grid container spacing={2} sx={{ padding: 2 }}>
      {[...Array(6)].map((_, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Skeleton variant="rounded" width="100%" height={200} />
          <Skeleton variant="text" width="80%" height={24} sx={{ mt: 1 }} />
        </Grid>
      ))}
    </Grid>
  )

  const handleEdit = async (video: Video) => {
    dispatch(AppActions.updateSelectedVideo(video))
    dispatch(AppActions.updatePageMode(PageMode.UPDATE))
    dispatch(AppActions.updateOpenedModule(ModuleName.videos))
    navigate(`/${Screen.videos}/${VideoScreen.selectVideoAvatar}`)
  }

  const handleDelete = (video: Video) => {
    setVideoToDelete(video)
    setDeletePopupOpen(true)
  }

  const handleClosePreview = () => {
    setVideoToPreview(null)
  }

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value)
  }

  return (
    <MainLayout>
      <SnackbarWithText props={snackbarProps} />
      <Topbar title="Videos" cta={"New Video +"} onClick={createNewVideo} />
      {isInitialLoading || isLoading ? (
        <div style={{ padding: 16 }}>
          <SkeletonLoader />
        </div>
      ) : allVideos.length === 0 ? (
        <Box sx={{ padding: 2 }}>
          <Typography
            style={{
              color: Colors.purple900,
              fontSize: "1.5rem",
              textAlign: "center",
            }}
            variant="body1"
          >
            Welcome to Beyond Presence! Let's get started -
          </Typography>
          <Typography
            style={{
              color: Colors.purple900,
              fontSize: "1.5rem",
              textAlign: "center",
              marginBottom: "2rem",
            }}
            variant="body1"
          >
            Create your first video
          </Typography>
          <Grid container justifyContent="center">
            <Grid
              display="flex"
              justifyContent="center"
              item
              xs={12}
              sm={6}
              md={4}
            >
              <EmptyCard onClick={createNewVideo} mainText="New Video" />
            </Grid>
          </Grid>
        </Box>
      ) : (
        <>
          <VideosList
            videos={currentPageVideos}
            onDeleteVideo={handleDelete}
            onEditVideo={handleEdit}
            onPreviewVideo={handlePreviewVideo}
          />
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
            sx={{ mt: 2, display: "flex", justifyContent: "center" }}
          />
        </>
      )}
      <DeletePopup
        open={deletePopupOpen}
        onConfirm={deleteSelectedVideo}
        onCancel={cancelDeletion}
        text={`Delete video "${videoToDelete?.name}"?`}
      />
      <ResponsiveVideoPlayerModal
        videoUrl={videoToPreview?.url}
        previewUrl={videoToPreview?.previewUrl}
        isOpen={!!videoToPreview}
        onClose={handleClosePreview}
      />
    </MainLayout>
  )
}
