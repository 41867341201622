import { Box, CircularProgress, Skeleton, Typography } from "@mui/material"
import {
  Copy,
  DownloadSimple,
  Pencil,
  Trash,
  Warning,
} from "@phosphor-icons/react"
import dayjs from "dayjs"
import React, { Fragment, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { useAppSelector } from "../../redux"
import { getLoadAvatarUrl } from "../../services"
import { ApiState, Colors, Video } from "../../types"
import Menu from "../menu"

interface VideosListProps {
  videos: Video[]
  onEditVideo: (video: Video) => void
  onDeleteVideo: (video: Video) => void
  onPreviewVideo: (video: Video) => void
}

interface VideoCardProps {
  video: Video
  onEditVideo: (video: Video) => void
  onDeleteVideo: (video: Video) => void
  onPreviewVideo: (video: Video) => void
}

export function VideoCard({
  video,
  onEditVideo,
  onDeleteVideo,
  onPreviewVideo,
}: VideoCardProps) {
  const [loading, setLoading] = useState(false)
  const [previewImg, setPreviewImg] = useState<string | null>(null)

  const isProcessing = video.state === ApiState.processing
  const isDraft = video.state === ApiState.pending
  const isReady = video.state === ApiState.ready
  const isError = video.state === ApiState.error

  const isAdmin = useAppSelector((state) => state.app.isUserAdmin)

  const navigate = useNavigate()

  useEffect(() => {
    const req = async () => {
      try {
        setLoading(true)
        if (video.state === ApiState.ready) {
          setPreviewImg(video.previewUrl)
        } else {
          const resp = await getLoadAvatarUrl(video.avatarId)
          setPreviewImg(resp.previewUrl)
        }
      } catch (e) {
        console.error(e)
      } finally {
        setLoading(false)
      }
    }
    req()
  }, [])

  const handleDownload = () => {
    const link = document.createElement("a")
    link.href = video.url
    link.setAttribute("download", `${video.name}.mp4`)
    link.setAttribute("target", "_blank")
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  return (
    <Fragment>
      <Box
        sx={{
          width: "22rem",
          padding: 2,
          backgroundColor: isDraft ? Colors.grey100 : Colors.white,
          borderRadius: 4,
          cursor: "pointer",
          position: "relative",
          boxShadow: "0px -2px 12px 0px rgba(0, 0, 0, 0.06)",
        }}
      >
        {loading ? (
          <Skeleton
            variant="rounded"
            width="100%"
            height="15rem"
            sx={{ borderRadius: "0.5rem" }}
          />
        ) : (
          <Box
            sx={{
              position: "relative",
              width: "100%",
              height: "15rem",
              borderRadius: "0.5rem",
              overflow: "hidden",
            }}
            onClick={isReady ? () => onPreviewVideo(video) : undefined}
          >
            <img
              src={previewImg}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
              alt={video.name}
            />

            {isProcessing && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: `${Colors.purple900}E6`,
                  opacity: 0.7,
                }}
              >
                <CircularProgress sx={{ color: Colors.white }} />
                <Typography sx={{ color: Colors.white, mt: 2 }}>
                  Processing...
                </Typography>
              </Box>
            )}

            {isError && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: `${Colors.grey300}`,
                  opacity: 0.7,
                }}
              >
                <Warning size={120} color={Colors.grey800} weight="fill" />
              </Box>
            )}
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            mt: "0.5rem",
          }}
        >
          <Typography sx={{ color: Colors.almostBlack }} variant="body1">
            {video?.name || "Draft"}
          </Typography>
          <Typography sx={{ color: Colors.grey800 }} variant="body2">
            {dayjs(video.createdAt).format("MMM DD, YYYY")}
          </Typography>
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: "0",
            right: "0",
            zIndex: 2,
            padding: "1.5rem",
            borderRadius: "0.25rem",
          }}
        >
          <Menu
            actions={[
              {
                label: "Delete Video",
                onClick: () => onDeleteVideo(video),
                icon: <Trash style={{ marginRight: "0.5rem" }} />,
              },
              isDraft && {
                label: "Edit Video",
                onClick: () => onEditVideo(video),
                icon: <Pencil style={{ marginRight: "0.5rem" }} />,
              },
              isAdmin && {
                icon: <Copy style={{ marginRight: "0.5rem" }} />,
                label: "Copy item to clipboard",
                onClick: () =>
                  navigator.clipboard.writeText(JSON.stringify(video)),
              },
              isReady && {
                label: "Download Video",
                onClick: handleDownload,
                icon: <DownloadSimple style={{ marginRight: "0.5rem" }} />,
              },
            ]}
          />
        </Box>
      </Box>
    </Fragment>
  )
}

export function VideosList({
  videos,
  onEditVideo,
  onDeleteVideo,
  onPreviewVideo,
}: VideosListProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        gap: 2,
        margin: 5,
      }}
    >
      {videos.map((video) => (
        <VideoCard
          key={video.id}
          onDeleteVideo={onDeleteVideo}
          onEditVideo={onEditVideo}
          onPreviewVideo={onPreviewVideo}
          video={video}
        />
      ))}
    </Box>
  )
}
