import React, { useEffect, useState } from "react"
import { useAppSelector } from "../redux"
import { createStripeSession } from "../services"
import { isDev } from "../utils"

export function useStripeTable() {
  const user = useAppSelector((state) => state.app.user)

  const [clientSecret, setClientSecret] = useState<string | null>(null)
  const pricingTableId = isDev
    ? "prctbl_1PvmyW2MW3vXJI7rYc3bQZot"
    : "prctbl_1Puw7E2MW3vXJI7r10zX1Rpu"

  const publishableKey = isDev
    ? "pk_test_51PRCjc2MW3vXJI7rHLUdJZ96pXwVnfPpRTMvc1pYsolAkCd0yCAsBPqmfNfJEHbTdSSu1YXjUkzcAbCviCLtfb7200r07bsbsH"
    : "pk_live_51PRCjc2MW3vXJI7rMmsyzAg1fJ5UE37iaP8lsNO0yFhBCvax72hqeRymyw5JYMxpPNB2vcFb87f39G5ShD3jkfIt00zMHTJpQD"

  useEffect(() => {
    if (!user?.uid) {
      return
    }

    const fetchClientSecret = async () => {
      try {
        const res = await createStripeSession(user.uid)
        setClientSecret(res)
      } catch (error) {
        console.error("Error fetching client secret:", error)
      }
    }

    fetchClientSecret()

    const script = document.createElement("script")
    script.src = "https://js.stripe.com/v3/pricing-table.js"
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [user?.uid])

  if (!clientSecret || !user?.uid) {
    return null // or a loading indicator
  }

  return React.createElement("stripe-pricing-table", {
    "pricing-table-id": pricingTableId,
    "publishable-key": publishableKey,
    "customer-session-client-secret": clientSecret,
  })
}
